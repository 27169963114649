import { Component, OnInit, Inject, LOCALE_ID, Input, SimpleChanges, OnChanges } from '@angular/core';
import { MatKeyboardRef, MatKeyboardComponent, MatKeyboardService,
   MAT_KEYBOARD_LAYOUTS, IKeyboardLayout } from '@ngx-material-keyboard/core';
import { KeyboardService } from '../../../@core/data/keyboard.service';

@Component({
  selector: 'app-keyboard',
  templateUrl: './keyboard.component.html',
  styleUrls: ['./keyboard.component.css']
})
export class KeyboardComponent implements OnInit {
  @Input() keyToPress: string;
  @Input() visibility = true;
  @Input() showColors = true;
  @Input() locale = 'ar';
  @Input() layout = 'AZERTY';
  @Input() showLatin = true;
  isShiftKey = false;
  matrix = [
    [1, 1, 1, 2, 3, 4, 4, 5, 3, 2, 1, 1, 1],
    [1, 2, 3, 4, 4, 5, 5, 3, 2, 1, 1, 1, 1],
    [1, 2, 3, 4, 4, 5, 5, 3, 2, 1, 1, 1],
    [1, 2, 3, 4, 4, 4, 5, 5, 3, 2, 1]
  ];

  shiftKeys = {
    ض: '\u064E',
    ص: '\u064B',
    ث: '\u064F',
    ق: '\u064F',
    ذ: '\u0651',
    ء: '\u0652',
    ؤ: '\u0650',
    ر: '\u064D',
    ت: '\u0640',
    ل: '\uFEF7',
    ف: '\uFEF9',
    \uFEFB: '\uFEF5',
    ا: '\u0627',
    إ: 'غ',
    آ: 'ى',
    أ: '\u0627',
    1612: 'ق',
    1613: 'س',
    1614: 'ض',
    1615:  'ث',
    1616: 'ش',
    1617: 'ذ',
    1618: 'ء'
  };

  keyboardLayout =
    {
      'en':
        ['`1234567890-=',
          'qwertyuiop[]\\',
          'asdfghjkl;\'',
          'zxcvbnm,./'],
      'fr':
        ['<&}\'(§è!çà)-',
          'azertyuiop^$',
          'qsdfghjklmù`',
          '@wxcvbn,;:='],
      'ar':
        ['ذ1234567890-=',
          'ضصثقفغعهخحجد',
          'شسيبل\u0627تنمكط\\',
          'ذئءؤر\uFEFBىةوزظ']
    };
  language = 'ar';
  constructor(private _kbService: KeyboardService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('keyToPress' in changes) {
    const letter = this._kbService.getCharFromLetter(this.keyToPress);
    if (letter) {
      this.isShiftKey = this._kbService.isShiftKey(this.keyToPress);
      this.keyToPress = letter;
      if (this.isShiftKey) {
        console.log(letter);
        this.keyToPress = this.getShiftKey(letter);
        console.log(this.keyToPress);
      }
    }
    }
  }

  getClassname(row, column){
    return 'c' + this.matrix[row][column];
  }

  getKeyFromShift(shiftKey) {
    for (const key in this.shiftKeys) {
      if (this.shiftKeys[key] == shiftKey) {
        return key;
      }
    }
    return '';
  }

  getShiftKey(char) {
    if (char in this.shiftKeys) {
      return this.shiftKeys[char];
    }
    return this.shiftKeys[char.charCodeAt(0)];
  }

  highlightKeyToPress(char) {
    return char.localeCompare(this.keyToPress, this.locale) == 0;
  }

  getLatinChar(row, column) {
    if (this.showLatin) {
      return this._kbService.arabicToLatin[this.layout][row][column];
    }

  }

}
