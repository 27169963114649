import { SettingsService } from './../../../@core/data/settings.service';
import { Component, Input, OnInit, NgZone } from '@angular/core';

import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { UserService } from '../../../@core/data/users.service';
import { AnalyticsService } from '../../../@core/utils/analytics.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Setting } from '../../../shared/models/setting';
import { ElectronService } from 'ngx-electron';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {


  @Input() position = 'normal';
  lang: string;
  settings: any ={};
  sharedSettings: any = {};
  user: any;

  userMenu = [];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private userService: UserService,
              private analyticsService: AnalyticsService,
              private translate: TranslateService,
              private zone: NgZone,
              private _electronService: ElectronService,
              private _settings: SettingsService,
              ) {
  }

  ngOnInit() {
    this._settings.settings.subscribe(settings => {
      this.sharedSettings = settings;
      this.user = `مرحباً ${this.sharedSettings.fullName}`;
    });

    this.settings = JSON.parse(localStorage.getItem('settings'));
    this.user = `مرحباً ${this.sharedSettings.fullName}`;
}

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }

  toggleSettings(): boolean {
    this.sidebarService.toggle(false, 'settings-sidebar');
    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }
}
