import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { KeyboardService } from '../../../@core/data/keyboard.service';

@Component({
  selector: 'app-hands',
  templateUrl: './hands.component.html',
  styleUrls: ['./hands.component.css']
})
export class HandsComponent implements OnInit, OnChanges {
@Input() keyToPress: string;
@Input() visibility = true;
finger: number;
language = 'ar';
fingers = { 
  en: [
  '§12qaz',
  '3wsx',
  '4edc',
  '56tfgrvb',
  ' ',
  ' ',
  '7yuhjnm',
  '8ik,',
  '9ol.',
  '0p;/-=[]\'/\r',
],
ar: [
  'ذ٢12١ضشئ',
  '3٣صسء',
  '٤ثيؤ4',
  '٥٦56لقفبلؤر\uFEFB',
  ' ',
  ' ',
  'ا7٧غعاتىة',
  '٨8هنة',
  '٩9خمو',
  '0٠-=حجدكط\\وزظ\r',
]

};

shiftKeys = {
  ض: '\u064E',
  ص: '\u064B',
  ث: '\u064F',
  ق: '\u064F',
  ذ: '\u0651',
  ء: '\u0652',
  ؤ: '\u0650',
  ر: '\u064D',
  ت: '\u0640',
  لا: '\uFEF5',
  ل: '\uFEF7',
  ف: '\uFEF9',
};

  constructor(private _kbService: KeyboardService) { }
  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.keyToPress = this.keyToPress == '⏎' ? '\r' : this.keyToPress;
    this.finger = this.updateHands();
  }
  updateHands(): number {
    if (this._kbService.isTashkeel(this.keyToPress) || this._kbService.isPonctuation(this.keyToPress)
    || this._kbService.isHamza(this.keyToPress)) {
      this.keyToPress = this.getKeyFromShift(this.keyToPress);
    }
    let char = this. _kbService.getCharFromLetter(this.keyToPress);
    if (!char) {
      char = this.keyToPress;
    }
    const finger = this.fingers[this.language].findIndex((fn, index) => {
     return fn.indexOf(char) > -1;
    });
    return finger;
  }

  getKeyFromShift(shiftKey) {
    for (const key in this.shiftKeys) {
      if(this.shiftKeys[key] == shiftKey) {
        return key;
      }
    }
    return '';
  }
}
