import { KeyboardService } from './keyboard.service';
import { of as observableOf, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ElectronService } from 'ngx-electron';
import { courses } from '../../../assets/courses/ar';
let counter = 0;
export enum Level {
    Beginner = 'beginner',
    Intermediate = 'intermediate',
    Advanced = 'advanced',
};
@Injectable()
export class CourseService {
    public text: String[];
    private courses = courses;

    private coursesArray: any[];

    constructor(private _electronService: ElectronService,
        private _kbService: KeyboardService
        ) {
        this.coursesArray = Object.values(this.courses);
    }

    getCourses(): Observable<any> {
        return observableOf(this.courses);
    }

    getCoursesArray(): Observable<any[]> {
        return observableOf(this.coursesArray);
    }

    getCourse(slug): Observable<any> {
            const course:any = this.courses.find(data => data.slug == slug);
            return observableOf(course);
    }

    getCourseText(keys:String[], wordCounts = 16, wordPerLine = 4){
        let text = [];
        let line = [];
        let word = [];
        for (let i = 0; i < wordCounts/wordPerLine; i++) {
            line = [];
            for (let j = 0; j < wordPerLine; j++) {
                let suffix = wordPerLine - 1 == j ? '⏎':' ';
                word = this.makeWord(keys.join(''), (Math.random() * 3) + 1, suffix)
                line.push(word);
            }
            text.push(line);
        }
        return text;
    }

    makeWord(chars: string, wordLength = 4, suffix = '') {
        let word = [];
        let position = 0;
        let currentChar = '';
        let previousChar = '';
        let isLastCharTashkeel = false;
        for (let i = 0; i < wordLength; i++) {
            currentChar = chars.charAt(Math.floor(Math.random() * chars.length));
           word.push({value: currentChar, isValid: true, isActive: false});
        }
        isLastCharTashkeel = this._kbService.tashkeel.includes(word[wordLength - 1]);
        for (let j = 0; j < word.length; j++) {
            position = j === 0 ? 0 : j < wordLength - 1 ? 1 : 2;
            word[j].value = this._kbService.getLetter(wordLength, previousChar, word[j].value, isLastCharTashkeel, position, 'ar', j);
          previousChar = currentChar;
          currentChar = word[j];
        }
        word.push({value: suffix, isValid: true, isActive: false});
        return word;
      }
}
