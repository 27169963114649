import { Lesson } from './lesson';

export class Course {
  title;
  slug;
  icon;
  level;
  description;
  lessons: Array<Lesson> = [];
}
