export const english = {
    welcome: 'Hello',
    errors: 'Errors',
    time: 'Time',
    speed: 'Speed',
    accuracy: 'Accuracy',
    wpm: 'WPM',
    next: 'Next',
    previous: 'Previous',
    close: 'Close',
    buy: 'Buy Now!',
    time_spent: 'Time spent',
    taskCompleted : 'Task completed!',
        msgWow: 'Congratulations! you\'ve completed succesfully.',
        great: 'Great',
    test_completed: 'Congratulation! You have completed the test.',
    test_completed_title: 'Test completed!',
    mac_help_info: `<i class="nb-info mr-1 ml-1"></i> For Mac user: Use the following guide to install Arabic into your Mac. 
    <br> Make sure the keyobard is either AZERTY or Querty (depending on your locale). The (ذ) should be on top left.`,
   
    validation: {
        required: 'Required field',
    },
    app: {
        name: 'Typing Tutor',
        address:'Villa No. 10 Impasse 7118 Ali Zlitane No. 1 Manar 2 Tunisia 2092',
        phone:'Phone:',
        settings: {
            theme: 'Theme' ,
                language: {
                    title: 'Change language',
                    ar: 'عربية',
                    en: 'English'
                            },
           keyboard: {
               title: 'Keyboard',
               hide: 'Hide',
               show: 'Show',
               showColors: 'Show colors',
               hideColors: 'Hide colors'
           },
           hands: {
               title: 'Hands',
               hide: 'Hide',
               show: 'Show'
           }
        }
    },
    menu: {
        contact:'Contact us',
        coursControls:'Lesson controls',
        addLangue: 'Add Arabic to your system',
        resultat: 'Statistics',
        titleCardTest :'Test your skills',
        dashboard: 'Dashboard',
        BuyYourCopy :'Buy and start learning!',
        BuyNow :'Buy now',
        numberTestsRest:'Remaining trial usage',
        numberTests:'From overall 20',
        settings: 'Settings',
        test: 'Test',
        account: 'Account',
        courses: 'Courses',
        auth: {
            auth: 'Auth',
            login: 'Login',
            register: 'Register',
            reset_password: 'Reset Password'
        }
    },
    checkout:{
        adress_app: 'Buying in Tunisia',
        your_copy_app: 'You can get a copy on a CD by visiting us today. For further details, please contact:',
        email:'Or email',
        address:'Address',
        activation:'Activating your copy',
        code_activation:'Enter your activation below',
        digital_acquisition:'Buying online',
        activate_copy:'In order to keep practicing, buy and activate your copy',
        educational_institutions:'Educational institutions, organizations, companies and distributors',
        if_you_are_interested:'Find our product useful and need more? Please feel free to contact us, to further discuss the details.'
    },
    help:{
        add_lang_pc : 'Add and switch input and display language preferences in Windows 10',
        add_input_language:"Add an input language",
        change_keyboard_language:'Adding an input language enables you to set a language-preference order for websites and apps, as well as change your keyboard language.',
        locate:'Select',
        start_button:' the Start',
        then_settings:'button, then Settings',
        time_region_language:'> Time & Language > Language.',
        select_add_language:'Select Add a language.',
        select_language_use:'Choose the language you want to use from the list, and then select Next.',
        review_features_language:'Review any language features you want to set or install, and select Install.',
        note_change_language:'Note: To switch the input language you\'re using in Windows 10, select the language abbreviation on the far right of the taskbar, and then select the language or input method you want to use. You can also press and hold the Windows logo key',
        cycle_changes_language:'and then repeatedly press the Spacebar to cycle through all your languages.',
        set_display_language:'Set your display language',
        display_language_default:'The display language you select changes the default language your entire operating system uses.',
        note_changes_language:'and then repeatedly press the Spacebar to cycle through all your languages.',
        go_to_start:'Go to Start',
        setting_lang:'Settings',
        select_lang_win:'Choose a language from the Windows display language menu.',
        install_language_pack:'Install a language pack for an existing language',
        preferred_languages:'If a language is listed under Preferred languages but doesn\'t appear on the Windows display language list, you\'ll first need to install its language pack, when available.',
        choose_preferred_languages:'Choose a language from Preferred languages, and then select Options.',
        download_language_pack:'Select Download from the Download language pack option.',
        select_pack:'After the language pack is installed, select Back',
        click_start:'Click Start',
        control_panel:'Control Panel.',
        change_keyboards_input_methods:'Under Clock, Language, and Region, click Change keyboards or other input methods.',
        if_dont_see_clock:'Note: If you don\'t see Clock, Language, and Region, click Category in the View by menu at the top of the page.',
        region_and_language:'In the Region and Language dialog box, on the Keyboards and Languages tab, click Change keyboards.',
        text_services_input_languages:'In the Text Services and Input Languages dialog box, under Default input language, click the language that you want to use as the default language.',
        if_the_language:'If the language that you want to use as the default input language is not listed, complete Steps 5 through 8.',
        installed_services:'Under Installed services, click Add.',
        expand_the_language:'Expand the language that you want to use as the default input language, and then expand Keyboard.',
        installed_servicesgs:'Under Installed services, click Add.',
        installed_servicesf:'Under Installed services, click Add.',
        ime:'Select the check box for the keyboard or Input Method Editor (IME) that you want to use, and then click OK. The language is added to the Default input language list.',
        click_preview:'To preview the keyboard layout, click Preview.',
        regional_and_language_options:'Note: In Classic View, double-click Regional and Language Options, and then click the Keyboards and Languages tab.',
        dialog_box:'In the Regional and Language dialog box, on the Keyboards and Languages tab, click Change keyboards.',
        applied:'Note: The default language that you select is applied to all programs on your computer that use this setting, including programs from other companies.',
        under_default:'Under Default input language, click the language that you want to use as the default language.',
        note_region:'Note: In Classic View, double-click Regional and Language Options, and then click the Keyboards and Languages tab.',
        click_data:'Click Date, Time, Language, and Regional Options, and then click Regional and Language Options.',
        classic_view:'Note: In Classic View, double-click Regional and Language Options',
        regional_dialog_box:'In the Regional and Language Options dialog box, click the Languages tab, and then under Text services and input languages, click Details.',
        service_click_lang:'In the Text Services and Input Languages dialog box, under Default input language, click the language that you want to use as the default language.',
        listed_lang:'If the language that you want to use as the default input language is not listed, complete Steps 5 through 7.',
        note_applied:'Note: The default language that you select is applied to all programs on your computer that use this setting, including programs from other companies.',
        under_install:'Under Installed services, click Add.',
        input_language_dialog_box:'In the Add Input Language dialog box, click the language that you want from the Input language list, and then click OK.',
        microsoft:'Microsoft:',
        source:'Sources :',
        office_support:'Office Support',
    },
    activation : {
        successTitle: 'Are you ready to learn arabic typing?',
        successText: 'To start practicing, press the \'START\' button!',
        activation: 'Activation',
        activation_key: 'Activation key',
        newAccount: 'Create new account',
        firstStep: 'First step',
        secondtStep: 'Second step',
        fullName: 'Full Name',
        am: 'I am',
        myFullName: 'My name',
        myAge: 'My age',
        myCountry: 'My country',
        fullNameMax:'Full Name must contain',
        to:'To',
        start: 'Start!',
        characters:'characters',
        addressEmail: 'Email address',
        emailRequired:'Email is required!',
        password: 'Password',
        passwordRequired:'Password is required!',
        fullnameRequired: 'Fullname is required!',
        confirmPassword: 'Confirm password',
        rePasswordRequired:'Repeat password is required!',
        country:'Country',
        countryRequired:'Country is required!',
        termsConditions: 'Do you accept terms conditions',
        next: 'Next',
        close: 'Close',
        haveAccount: 'Have an account',
        signIn: 'Sign In',
        favoriteMovie:'Your favorite movie',
        previous:'Previous',
        submit:'Submit',
        typeskeyboard:'Keyboard type',
        gender:{
            name :'Gender',
            male:'Male',
            female:'Female',
            boy:'Boy',
            girl:'Girl'
        },
        age:'Age',
        totalTrial:'Of 20 times',
        totalTrialRest:'Uses remaining',
        tryApp:'Or I want to try the application first (Duration: 20 uses)',
        activateCopy:'To activate your copy, enter the activation number in the field below. Your trial version includes 20 uses.',
        activateCopyWeb:'To start using Arabic typing press the submit button.',

    },
    settings : {
        save:'Save',
        settings:'Settings',
        applicationLangage : 'Application langage:',
        english:'English',
        arabic:'Arabic',
        french:'French',
        learningLangage :'Learning langage:',
        keyboardLayout :'Keyboard layout:',
        keyboard :'Keyboard',
        hands :'Hands:',
        hidden:'Hidden',
        info : 'This feature is not available in current version',
        fullName : 'FullName',
        age: 'Age',
        country:'Country',
        gender:{
            name :'Gender'
        }
    },
    footer : {
        typingTutor:'Arabic Typing Tutor',
        createdWith: 'created with',
        by:'By',
        noqta:'Noqta',

    },
    beginner_level: 'Beginner level',
    advanced_level: 'Advanced level',
    description: 'Course for beginners',
    lesson_advanced: 'Advanced level course',
    lesson_one: 'First Lesson',
    lesson_tow: 'Second Lesson',
    lesson_three: 'Third Lesson',
    lesson_fourth: 'Fourth Lesson',
    task_1: 'Task One',
    task_2: 'Task Two',
    task_3: 'Task Three',
    task_4: 'Task Four',
    task_5: 'Task Five',
    task_6: 'Task Six',
    home_role: 'Home Row',
    top_row: 'Top Row',
    bottom_row: 'Bottom Row',
    top_key_row: 'The top row keys that are directly above the home row.',
    bottom_key_row: 'The bottom row keys are directly below the home row.',
    position_finger: 'This row is the initial position of the fingers.',
    second_tranche: 'Advanced level course',
    description_first_lesson: 'First advanced lesson',
    description_second_lesson: 'Second advanced lesson',
    description_lesson_three: 'Third advanded level',
    description_lesson_fourth: 'Fourth advanced level',
    tour: {
        content_1: "The menu helps you access the main pages of the application",
        title_1: "Main menu",
        content_2: "Every course contains lessons. To start typing, click the the course title.",
        title_2: "Course",
        content_3: "Every course is composed of lessons. Every lessons contains a set of excercises.",
        title_3: "Lesson",
        content_4: "Every excercise teaches you on set of keys. As you progress, the excersice difficulty increases.",
        title_4: "Excercise",
        content_5: "Need help? Feel free to contact us.",
        title_5: "Contact us",
        content_6: "You can buy and activate your copy here to start learning",
        title_6: "Activation",
    },
};