import { Component, OnInit, Input, HostListener, Output, EventEmitter,
   OnChanges, SimpleChanges, ViewChild, ElementRef, ChangeDetectorRef
   , Inject } from '@angular/core';
import { KeyboardService } from '../../../@core/data/keyboard.service';
@Component({
  selector: 'app-textarea',
  templateUrl: './ngx-textarea.component.html',
  styleUrls: ['./ngx-textarea.component.css']
})
export class NgxTextareaComponent implements OnInit, OnChanges {
  message: string;
  /** Track line position **/
  line = { position: 0, value: '' };
  /** Track current word **/
  word = { position: 0, value: '' };
  /** Track current character position **/
  char = { position: 0, value: '' };
  /** the current pressed key **/
  pressedKey: KeyboardEvent;
  stats = {errors: 0, words: 0, chars: 0 };
  courseStats = { lesson: 0, task: 0};
  hasStarted = false;
  /** mute sound */
  @Input() mute = false;
  // The text to type
  @Input() text: any;
  // Key press event
  @Output() keyPressed = new EventEmitter<any>();
  @Output() keyToPress = new EventEmitter<any>();
  @Output() taskCompleted = new EventEmitter<boolean>();
  @Output() lessonCompleted = new EventEmitter<boolean>();
  @Output() courseCompleted = new EventEmitter<boolean>();
  @Output() courseStarted = new EventEmitter<boolean>();
  @Output() statsUpdated = new EventEmitter<any>();
  @Output() courseProgressUpdated = new EventEmitter<any>();
   @ViewChild('container') container: ElementRef;

  constructor(private _kbService: KeyboardService,
    private cd: ChangeDetectorRef) { }

  ngOnInit() {
    // Update counters
    this.updateCounterValues();
    // Initiate course stats
    this.courseProgressUpdated.emit(this.courseStats);
  }

  ngOnChanges(changes: SimpleChanges) {
      this.resetCourse();
      this.cd.detectChanges();
}
  updateTracker(isCorrect): any {
    this.getCurrentCharacter().isValid = isCorrect;
  }

  updateCourseProgress(){
    this.taskCompleted.emit(true);
    this.resetCourse();
      console.log('Task completed !');

  }

  private resetCourse() {
    this.scrollToTop();
      this.hasStarted = false;
      this.stats = { errors: 0, words: 0, chars: 0 };
      this.courseStarted.emit(false);
      this.statsUpdated.emit({ ...this.stats });
  }

  updateProgress(): any {
    let key = this.pressedKey.keyCode || this.pressedKey.charCode;
    // Backspace pressed
    if (key == 8 || key == 46) {
      this.decrementCharacterProgress();
    } else {
      this.updateCharacterProgress();
    }
  }

  private decrementCharacterProgress() {
    if (this.char.position == 0) {
      this.decrementWordProgress();
    }
    else {
      if(this.getCurrentCharacter().isValid == false){
        this.stats.errors--;
        this.getCurrentCharacter().isValid = true;
    }
      this.stats.chars--;
      this.char.position--;
      if(this.getCurrentCharacter().isValid == false){
        this.stats.errors--;
        this.getCurrentCharacter().isValid = true;
    }
      this.getCharacter(this.line.position, this.word.position, this.char.position);
    }
  }

  private getCurrentCharacter() {
    return this.text[this.line.position][this.word.position][this.char.position];
  }

  private decrementWordProgress() {
    if (this.word.position == 0) {
      this.decrementLineProgress();
    }
    else {
      if(this.getCurrentCharacter().isValid == false){
        this.stats.errors--;
      }
      this.getCurrentCharacter().isValid = true;
      this.word.position--;
      this.stats.words--;
      this.stats.chars--;
      this.getWord(this.line.position, this.word.position);
      this.char.position = this.word.value.length - 1;
      this.getCharacter(this.line.position, this.word.position, this.char.position);
    }
  }

  private decrementLineProgress() {
    if(this.getCurrentCharacter().isValid == false){
      this.stats.errors--;
      this.getCurrentCharacter().isValid = true;
    }
    
    if (this.line.position > 0) {
      this.stats.words--;
      this.stats.chars--;
      this.scrollLine(-38);
      this.line.position--;
      this.getLine(this.line.position);
      this.word.position = this.text[this.line.position].length - 1;
      this.getWord(this.line.position, this.word.position);
      this.char.position = this.word.value.length - 1;
      this.getCharacter(this.line.position, this.word.position, this.char.position);
    }
  }

  private updateCharacterProgress() {
    if (this.char.position < this.word.value.length) {
      if (this.char.position == this.word.value.length - 1 && this.word.position < this.text[this.line.position].length) {
        this.updateWordProgress();
      }
      else {
        this.char.position++;
        this.stats.chars++;
        this.getCharacter(this.line.position, this.word.position, this.char.position);
      }
    }
  }

  private updateWordProgress() {
    if (this.word.position == this.text[this.line.position].length - 1 && this.line.position < this.text.length) {
      this.updateLineProgress();
    }
    else {
      this.word.position++;
      this.stats.words++;
      this.stats.chars++;
      this.char.position = 0;
      this.getWord(this.line.position, this.word.position);
      this.getCharacter(this.line.position, this.word.position, this.char.position);
    }
  }

  private updateLineProgress() {
    this.line.position++;
    this.char.position = 0;
      this.word.position = 0;
      this.stats.chars++;
      this.stats.words++;
    if (this.line.position == this.text.length) {
      this.line.position = 0;
      this.updateCourseProgress();
    }
    this.scrollLine(this.line.position == 0? 0:38);
      this.getCharacter(this.line.position, this.word.position, this.char.position);
      this.getWord(this.line.position, this.word.position);
      this.getLine(this.line.position);
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    event.preventDefault();
    if(!this.hasStarted) {
      this.hasStarted = true;
      this.courseStarted.emit(true);
    }
    this.pressedKey = event;
    let key = this.pressedKey.keyCode || this.pressedKey.charCode;
    const pressedKey = this.filterKey(key);
    this.keyPressed.emit(pressedKey);
    this.handleKeyPress();
  }

  filterKey(key, useUtf8 = true) {
    let hamzasArray;
    const hamzasUtf8 = {66: '\uFEF5',71: '\uFEF7', 84: '\uFEF9' };
    const hamzas = {66: 'ﻵ',71: 'ﻷ', 84: 'ﻹ' };
    hamzasArray = useUtf8? hamzasUtf8:hamzas;
    if(this.pressedKey.shiftKey && key in hamzas){
        return hamzasArray[key];
    } else {
      let lam = useUtf8? '\uFEFB': 'لا';
    return key == 66? lam:this.pressedKey.key;
    }
  }

  handleKeyPress(): any {
    // Update counters
    this.updateCounterValues();
    let key = this.pressedKey.keyCode || this.pressedKey.charCode;
    const modifierKey = [9, 16, 17, 18, 20, 27, 37, 38, 39, 40];

    if (!modifierKey.includes(key)) {
      var audio = new Audio('../../../../assets/audio/sound.mp3');
  if(!this.mute) {
      audio.play();
    }
      if(key != 8 && key != 46) {
      const isCorrect = this.compare();
      this.updateTracker(isCorrect);
      }
    // Record progress
    this.updateProgress();
    this.statsUpdated.emit({...this.stats});
  }
  }


  private compare() {
    // Check input is equal to current value
    let char: string| boolean = this.char.value == '⏎'? 'Enter': this.char.value == ' '
    ? ' ': this._kbService.getCharFromLetter(this.char.value);

    let key = this.pressedKey.keyCode || this.pressedKey.charCode;
    const pressedKey = this.filterKey(key, false);
    if (char == pressedKey) {
      return true;
    }
    this.stats.errors++;
    return false;
  }

  private updateCounterValues() {
    this.getCharacter(this.line.position, this.word.position, this.char.position);
    this.getWord(this.line.position, this.word.position);
    this.getLine(this.line.position);
  }

  private getLine(position): void {
    if (this.text) {
    this.line.value = this.text[position].map(word => word.map(char => char.value).join(''));
  }
  }

  private getCharacter(line, word, position): void {
    if (this.text) {
    this.char.value = this.text[line][word][position].value;
    this.keyToPress.emit(this.char.value);
    }
  }

  private getWord(line, position): void {
    if (this.text) {
    this.word.value = this.text[line][position].map(char => char.value).join('');
    }
  }

  isLineActive(i){
    return this.line.position == i;
  }

  isWordActive(i, j){
    return this.word.position == j && this.line.position == i;
  }

  isCharActive(i, j, k){
    return this.char.position == k && this.word.position == j && this.line.position == i;
  }

  isInvalid(i, j, k) {
    return this.text[i][j][k].isValid == false;
  }
  scrollLine(offset) {
    const el = document.querySelector('#container');
    el.scrollBy(el.getBoundingClientRect().left, offset);
  }

  scrollToTop() {
    const el = document.querySelector('#container');
    el.scrollTop = 0;
  }

  showWord(word) {
    const joinedWord = word.map(char => {
       return char.value;
    });
    return joinedWord.join('');
  }
}
