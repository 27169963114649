import { of as observableOf, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ElectronService } from 'ngx-electron';
import { KeyboardService } from './keyboard.service';

@Injectable()
export class TestService {


  public text: String[];
  private words = [
    'كأْساً',
    'طويلٌ',
    'ملائِكةٍ',
    'كَتَبَ',
    'مُحمَّدٌ',
    'ا\uFEF9نسان',
    'ا\uFEF5خر',
    'ب\uFEFB',
    'سَبَقَ',
    '\uFEFB',
    'من',
    'دقائق',
    'سيارة',
    'سعيدة',
    'العشاء',
    'الغرفة',
    'وسوف',
    'تذكر',
    'أصدق',
    'ألف',
    'بنفسك',
    'شباب',
    'الماضي',
    'دعونا',
    'ا\uFEFBسبوع',
    'نتحدث',
    'نسيت',
    'بأنني',
    'منزل',
    'وضع',
    'ولد',
    'جاهز',
    'رسالة',
    'ابن',
    'حقيقة',
    'ا\uFEFBسد',
    'حين',
    'عائلة',
    'أدري',
    'وكان',
    'القائد',
    'للمنزل',
    'مساعدتك',
    'غدا',
    'ظننت',
    'ولن',
    'تحرك',
    'يهم',
    'تبقى',
    'الطبيب',
    'اسم',
    'انظري',
    'أتذكر',
    'فترة',
    'ساعات',
    'تفكر',
    'تحصل',
    'بأي',
    'حديقة',
    'انتصر',
    'السماء',
    'مبتسم',
    'أتتْ',
    'طلع',
    'البدر',
    'علينا',
    'فرحنا',
    'يتعاونان',
    'يدرس',
    'السفينة',
    'أبحرت',
    'الغيوم',
    'في',
    'من',
    'بغداد',
    'القدس',
    'عمّان',
    'الرياض',
    'المدينة',
    'مكّة',
    'الجزائر',
    'الرباط',
    'تونس',
    'طرابلس',
    'القاهرة',
    'عدن',
    'دبي',
    'الدوحة',
    'الكويت',
    'المنامة',
    'مسقط',
    'دمشق',
    'إسطنبول',
    'خرطوم',
    'البوسنة',
    'ألبانيا',
    'الاندلس',
    'سافر',
    'مع',
    'عائلته',
    'إلى',
    'اندونيسيا',
    'ماليزيا',
    'التشاد',
    'السنغال',
    'مالي',
    'أريتريا',
    'فاطمة',
    'زينب',
    'أخته',
    'أمّها',
    'الأزهار',
    'العصافير',
    'العندليب',
    'البنفسج',
    'الأقحوان',
    'حذائه',
    'القميص',
    'جميل',
    'مسرورة',
    'مبتهجة',
    'البنات',
    'في',
    'الساحة',
    'يزْرعن',
    'يطالعنا',
    'بجدية',
    'و',
    'متحمس',
    'فخور',
    'الدرس',
    'القسم',
    'الجامعة',
    'الكتاب',
    'الساحة',
    'الجِبال',
    'الحمامة',
    'القطّة',
    'الضيعة',
    'الخيول',
    'الغروب',
    'الشمس',
    'القمر',
    'البستان',
    'الحوت',
    'الأرض',
    'طيّبة',
    'حسناء',
    'يحبّها',
    'الإخلاص',
    'يتفانى',
    'يزرعون',
    'يحصد',
    'تجني',
    'الزيتون',
    'الرّمان',
    'العِنَب',
    'الأنهار',
    'النسيم',
    'يستنشق',
    'يقطع',
    'الخبز',
    'الطريق',
    'يجلب',
    'أكلت',
    'مع',
    'يقرأ',
    'تطالع',
    'الموج',
    'الصدف',
    'الرّمال',
    'يجري',
    'تراقب',
    'يقذف',
    'يصنع',
    'المروج',
    'ينهض',
    'يصبر',
    'يتعب',
    'يواصل',
    'تحقق',
    'الهدف',
    'الغاية',
    'النبيلة',
    'السامية',
    'الشريف',
    'الصادق',
    'المصدوق',
    'يدافع',
    'يثابر',
    'يحترم',
    'تعين', 
    'يذود',
    'ترفع',
    'يفتخر',
    'تسانده',
    'السعودية',
    'قطر',
    'المغرب',
    'واحد',
    'موحّد',
  'فلسطين',
  'حرة',
  'الوطن',
  'العزة',
  'الوحدة',
  'تشاورا',
  'اقنعته',
  'السودان',
  'موريطانيا',
  'ليبيا',
  'البحرين',
  'السلطنة',
  'اليمن',
  'الإمارات',
  'سوريا',
  'لبنان',
  'بيروت',
  'مصر',
  'تونس',
  'النخل',
  'البئر',
  'الرصيف',
  'الزرافة',
  'النحل',
  'العسل',
  'الشهد',
  'من',
  'إلى',
  'عن',
  'الحصان', 
  'الإبل',
  'الساقية', 
  'القيروان',
  'غزة',
  'الخزانة',
  'الباب',
  'النافذة',
  'القاعة',
  'الغرفة',
  'المكتب',
  'الكرسي',
  'القدح',
  'الفنجان',
  'الصحن',
  'الآنية',
  'الورود',
  'المطبخ',
  'البهو',
  'الطبق',
  'الفاكهة',
  'الجوز',
  // 'باكستان',
  // 'أفغانستان',
  // 'بنغلاداش',
  // 'أوزباكستان',
  'الهند',
  'الصين',
  'بكين',
  'الإنارة',
  'النار',
  'الحطب',
  'يشغل',
  'تمشي',
  'يجلسون',
  'يتسائل',
  'تجيب',
  'يدوّن',
  'يذوب',
  'الحديد',
  'الحافلة',
  'الطائرة',
  'الطير',
  'المقود',
  'الدّراجة',
  'الهاتف',
  'القطار',
  'الجسر',
  'المعبر',
  'الواحة',
  'البحر',
  'النهار',
  'العصر',
  'الساعة',
  'يبارز',
  'التفاح',
  'يأكلونَ',
  'تشربون',
  'يجلس',
  'الجامع',
  'المسجد',
  'الحي',
  'القرية',
  'المدينة',
  'السور',
  'شمعة',
  'الذهب',
  'الياقوت',
  'الجواهر',
];
  private test: any = '';
  constructor( private _kbService: KeyboardService ) { }

   getTestText(wordCounts = 80, wordPerLine = 6) {
    let line = [];
    let word = [];
    let text = [];
    for (let i = 0; i < wordCounts / wordPerLine; i++) {
      line = [];
      for (let j = 0; j < wordPerLine; j++) {
          let suffix = wordPerLine - 1 == j ? '⏎':' ';
          word = this.makeWord(suffix);
          line.push(word);
      }
      text.push(line);
  }
  return text;
  }

  makeWord (suffix = '') {
    let word = [];
    let position = 0;
    let previousChar = '';
    let isLastCharTashkeel = false;
    const wordArray = this.words[Math.round(Math.random() * (this.words.length - 1))].split('');
    isLastCharTashkeel = this._kbService.tashkeel.includes(wordArray[wordArray.length - 1]);
    for (let i = 0; i < wordArray.length; i++) {
      position = i === 0 ? 0 : i < wordArray.length - 1 ? 1 : 2;
      const char = this._kbService.getLetter(wordArray.length, previousChar, wordArray[i], isLastCharTashkeel, position, 'ar', i);
      word.push({value: char, isValid: true, isActive: false});
      previousChar = wordArray[i];
    }
    word.push({value: suffix, isValid: true, isActive: false});
    return word;
  }
}
