import { constructor } from 'stream';
import { Component, Input } from '@angular/core';
import { ElectronService } from 'ngx-electron';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">{{'footer.typingTutor' | translate}} {{version}} | 
    {{'footer.createdWith' | translate}} ♥ {{'footer.by' | translate}} <b><a href="#"  
    (click)="open($event, url.site)">{{'footer.noqta' | translate}}</a></b> 2018</span>
    <div class="socials">
      <a href="#" (click)="open($event, url.site)" class="ion ion-link"></a>
      <a href="#" (click)="open($event, url.facebook)" class="ion ion-social-facebook"></a>
      <a href="#" (click)="open($event, url.twitter)" class="ion ion-social-twitter"></a>
      <a href="#" (click)="open($event, url.linkedin)" class="ion ion-social-linkedin"></a>
    </div>
  `,
})
export class FooterComponent {
  version;
  private _electron;
  url = {
    site: 'http://noqta.tn',
    facebook: 'https://facebook.com',
    twitter: 'https://twitter.com',
    linkedin: 'https://linkedin.com'
  }
  constructor(private _electronService: ElectronService,translate: TranslateService,) {
    if (this._electronService.isElectronApp) {
      this.version = this._electronService.remote.app.getVersion();
    } else {
      this.version = environment.version;
    }
  }

  open(e, url) {
    if (this._electronService.isElectronApp) {
    e.preventDefault();
    this._electronService.shell.openExternal(url);
    } else {
      window.location.href = url;
    }
  }

}
