import { Component, Input } from '@angular/core';

@Component({
  selector: 'ngx-indicator-card',
  styleUrls: ['./indicator-card.component.scss'],
  template: `
    <nb-card (click)="on = !on" [ngClass]="{'off': !on}">
      

      <div class="details">
        <div class="title text-center mb-2">{{ text }}<span class="status text-center"> {{ unit }}</span></div>
        <div class="title text-center"> {{ label }}</div>
      </div>
    </nb-card>
  `,
})
export class IndicatorCardComponent {

  @Input() text: string;
  @Input() label: string;
  @Input() unit:string;
  @Input() on = true;
}
