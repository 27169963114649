export const countries =
[
  {
    code: "JO",
    name: "الأردن"
  },
  {
    code: "AE",
    name: "الامارات العربية المتحدة"
  },
  {
    code: "BH",
    name: "البحرين"
  },
  {
    code: "DZ",
    name: "الجزائر"
  },
  {
    code: "SD",
    name: "السودان"
  },
  {
    code: "SO",
    name: "الصومال"
  },
  {
    code: "IQ",
    name: "العراق"
  },
  {
    code: "KW",
    name: "الكويت"
  },
  {
    code: "MA",
    name: "المغرب"
  },
  {
    code: "SA",
    name: "المملكة العربية السعودية"
  },
  {
    code: "YE",
    name: "اليمن"
  },
  {
    code: "TN",
    name: "تونس"
  },
  {
    code: "SY",
    name: "سوريا"
  },
  {
    code: "OM",
    name: "عمان"
  },
  {
    code: "PS",
    name: "فلسطين"
  },
  {
    code: "QA",
    name: "قطر"
  },
  {
    code: "LB",
    name: "لبنان"
  },
  {
    code: "LY",
    name: "ليبيا"
  },
  {
    code: "EG",
    name: "مصر"
  },
  {
    code: "MR",
    name: "موريتانيا"
  },
    {
      code: "ES",
      name: "أسبانيا"
    },
    {
      code: "AU",
      name: "أستراليا"
    },
    {
      code: "AM",
      name: "أرمينيا"
    },
    {
      code: "AZ",
      name: "أذربيجان"
    },
    {
      code: "AF",
      name: "أفغانستان"
    },
    {
      code: "AL",
      name: "ألبانيا"
    },
    {
      code: "DE",
      name: "ألمانيا"
    },
    {
      code: "AO",
      name: "أنجولا"
    },
    {
      code: "UZ",
      name: "أوزبكستان"
    },
    {
      code: "UG",
      name: "أوغندا"
    },
    {
      code: "ET",
      name: "اثيوبيا"
    },
    {
      code: "ER",
      name: "اريتريا"
    },
    {
      code: "BR",
      name: "البرازيل"
    },
    {
      code: "PT",
      name: "البرتغال"
    },
    {
      code: "BA",
      name: "البوسنة والهرسك"
    },
    {
      code: "GA",
      name: "الجابون"
    },
    {
      code: "ME",
      name: "الجبل الأسود"
    },
    {
      code: "DK",
      name: "الدانمرك"
    },
    {
      code: "CV",
      name: "الرأس الأخضر"
    },
    {
      code: "SN",
      name: "السنغال"
    },
    {
      code: "SE",
      name: "السويد"
    },
    {
      code: "CN",
      name: "الصين"
    },
    {
      code: "CM",
      name: "الكاميرون"
    },
    {
      code: "CG",
      name: "الكونغو - برازافيل"
    },
    {
      code: "HU",
      name: "المجر"
    },
    {
      code: "MX",
      name: "المكسيك"
    },
    {
      code: "GB",
      name: "المملكة المتحدة"
    },
    {
      code: "NO",
      name: "النرويج"
    },
    {
      code: "AT",
      name: "النمسا"
    },
    {
      code: "NE",
      name: "النيجر"
    },
    {
      code: "IN",
      name: "الهند"
    },
    {
      code: "US",
      name: "الولايات المتحدة الأمريكية"
    },
    {
      code: "JP",
      name: "اليابان"
    },
    {
      code: "GR",
      name: "اليونان"
    },
    {
      code: "ID",
      name: "اندونيسيا"
    },
    {
      code: "IR",
      name: "ايران"
    },
    {
      code: "IT",
      name: "ايطاليا"
    },
    {
      code: "PK",
      name: "باكستان"
    },
    {
      code: "BW",
      name: "بتسوانا"
    },
    {
      code: "BM",
      name: "برمودا"
    },
    {
      code: "BE",
      name: "بلجيكا"
    },
    {
      code: "BG",
      name: "بلغاريا"
    },
    {
      code: "BD",
      name: "بنجلاديش"
    },
    {
      code: "BJ",
      name: "بنين"
    },
    {
      code: "BF",
      name: "بوركينا فاسو"
    },
    {
      code: "BI",
      name: "بوروندي"
    },
    {
      code: "PL",
      name: "بولندا"
    },
    {
      code: "PE",
      name: "بيرو"
    },
    {
      code: "TZ",
      name: "تانزانيا"
    },
    {
      code: "TM",
      name: "تركمانستان"
    },
    {
      code: "TR",
      name: "تركيا"
    },
    {
      code: "TT",
      name: "ترينيداد وتوباغو"
    },
    {
      code: "TD",
      name: "تشاد"
    },
    {
      code: "TG",
      name: "توجو"
    },
    {
      code: "KM",
      name: "جزر القمر"
    },
    {
      code: "CF",
      name: "جمهورية افريقيا الوسطى"
    },
    {
      code: "CZ",
      name: "جمهورية التشيك"
    },
    {
      code: "CD",
      name: "جمهورية الكونغو الديمقراطية"
    },
    {
      code: "ZA",
      name: "جمهورية جنوب افريقيا"
    },
    {
      code: "GE",
      name: "جورجيا"
    },
    {
      code: "DJ",
      name: "جيبوتي"
    },
    {
      code: "RW",
      name: "رواندا"
    },
    {
      code: "RU",
      name: "روسيا"
    },
    {
      code: "RO",
      name: "رومانيا"
    },
    {
      code: "ZM",
      name: "زامبيا"
    },
    {
      code: "ZW",
      name: "زيمبابوي"
    },
    {
      code: "CI",
      name: "ساحل العاج"
    },
    {
      code: "SK",
      name: "سلوفاكيا"
    },
    {
      code: "SI",
      name: "سلوفينيا"
    },
    {
      code: "CH",
      name: "سويسرا"
    },
    {
      code: "RS",
      name: "صربيا"
    },
    {
      code: "TJ",
      name: "طاجكستان"
    },
    {
      code: "GM",
      name: "غامبيا"
    },
    {
      code: "GH",
      name: "غانا"
    },
    {
      code: "GF",
      name: "غويانا"
    },
    {
      code: "GY",
      name: "غيانا"
    },
    {
      code: "GN",
      name: "غينيا"
    },
    {
      code: "GQ",
      name: "غينيا الاستوائية"
    },
    {
      code: "GW",
      name: "غينيا بيساو"
    },
    {
      code: "FR",
      name: "فرنسا"
    },
    {
      code: "FI",
      name: "فنلندا"
    },
    {
      code: "CY",
      name: "قبرص"
    },
    {
      code: "KG",
      name: "قرغيزستان"
    },
    {
      code: "KZ",
      name: "كازاخستان"
    },
    {
      code: "HR",
      name: "كرواتيا"
    },
    {
      code: "CA",
      name: "كندا"
    },
    {
      code: "KR",
      name: "كوريا الجنوبية"
    },
    {
      code: "KP",
      name: "كوريا الشمالية"
    },
    {
      code: "KE",
      name: "كينيا"
    },
    {
      code: "LU",
      name: "لوكسمبورج"
    },
    {
      code: "LR",
      name: "ليبيريا"
    },
    {
      code: "LT",
      name: "ليتوانيا"
    },
    {
      code: "LI",
      name: "ليختنشتاين"
    },
    {
      code: "LS",
      name: "ليسوتو"
    },
    {
      code: "MT",
      name: "مالطا"
    },
    {
      code: "ML",
      name: "مالي"
    },
    {
      code: "MY",
      name: "ماليزيا"
    },
    {
      code: "MG",
      name: "مدغشقر"
    },
    {
      code: "MK",
      name: "مقدونيا"
    },
    {
      code: "MW",
      name: "ملاوي"
    },
    {
      code: "MN",
      name: "منغوليا"
    },
    {
      code: "MZ",
      name: "موزمبيق"
    },
    {
      code: "MD",
      name: "مولدافيا"
    },
    {
      code: "MC",
      name: "موناكو"
    },
    {
      code: "MM",
      name: "ميانمار"
    },
    {
      code: "NA",
      name: "ناميبيا"
    },
    {
      code: "NP",
      name: "نيبال"
    },
    {
      code: "NG",
      name: "نيجيريا"
    },
    {
      code: "NZ",
      name: "نيوزيلاندا"
    },
    {
      code: "NL",
      name: "هولندا"
    },
    {
      code: "HK",
      name: "هونج كونج الصينية"
    }
  ]