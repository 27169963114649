export const french = {
    menu: {
        dashboard: 'Tableau de bord',
        settings:  'Paramètres',
        auth: {
            auth: 'S\'authentifier',
            login: 'Se connecter',
            register: 'S\'incrire',
            reset_password: 'Changer mot de passe'
        }
    }
};