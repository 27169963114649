import {
  Component,
  OnInit,
  Inject,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  TemplateRef,
  ViewEncapsulation,
  NgZone
} from '@angular/core';
import { NbAuthService, NB_AUTH_OPTIONS, NbAuthResult } from '@nebular/auth';
import { Router, ActivatedRoute } from '@angular/router';
import { getDeepFromObject } from '@nebular/auth/helpers';
import { ElectronService } from 'ngx-electron';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { countries } from '../../../../../assets/i18n/countries';
import { NbLayoutDirectionService, NbLayoutDirection, NbThemeService } from '@nebular/theme';
import { ToasterService } from 'angular2-toaster';
import { environment } from '../../../../../environments/environment.prod';

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ActivationComponent implements OnInit {
  redirectDelay = 0;
  showMessages: any = {};
  strategy = '';
  isActivated = false;
  submitted = false;
  errors: string[] = [];
  messages: string[] = [];
  setting: any = {
    name: '',
    age: '',
    country: 'TN',
    gender: 'male',
    activation_type: 'trial',
    language: 'en',
    keyboard:
    {
      layout: 'QWERTY',
      visibility: true,
      show_colors: true,
    },
    hands: {
      visibility: true,
    }
  };
  user: any = { gender: 'male' };
  slug: String;
  isTrial = false;

  modalRef: BsModalRef;
  selected: String;
  optionOnBlur: any;
  countries = countries;
  directions = NbLayoutDirection;
  toast: any;
  tokens = ['TRIAL01298', '5602GABS', 'IYHT9870P', '45607MAZETH'];
  isElectronApp = false;
  isStoreApp = false;

  constructor(
    protected service: NbAuthService,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    protected cd: ChangeDetectorRef,
    private _route: ActivatedRoute,
    private directionService: NbLayoutDirectionService,
    private _electronService: ElectronService,
    protected router: Router,
    private modalService: BsModalService,
    private translate: TranslateService,
    private toasterService: ToasterService,
    private zone: NgZone,
    private themeService: NbThemeService,
  ) {}

  ngOnInit() {
    this.isStoreApp = environment.isStoreApp;
    this.changeLanguage('en');
    this.isElectronApp = this._electronService.isElectronApp;
    if (this._electronService.isElectronApp) {
    this._electronService.ipcRenderer.send(
      'getSettings',
      this.user,
      this.setting,
      this.isTrial
    );
    this._electronService.ipcRenderer.on('getSettingsReady', (event, res) => {
      if (JSON.stringify(res) !== '{}') {
        this.setting = res.settings;
        this.isActivated = true;
      }
      this.cd.detectChanges();
    });
  }
  }

  findCountry(countryName) {
    const country = this.countries.find(cn => cn.name === countryName);
    this.user.country = country.code;
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
 
  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }

  typeaheadOnBlur(event: any): void {
    this.optionOnBlur = event.item;
  }

  generateTrialKey() {
    if (this._electronService.isElectronApp || !this.isStoreApp) {
    this._electronService.ipcRenderer.send('getTrialKey');
    this._electronService.ipcRenderer.on('trialKeyReady', (event, key) => {
      this.setting.activation_key = key;
      this.isTrial = true;
      this.setting.activation_type = 'trial';
    });
  } else {
    this.setting.activation_key = this.generateKey(this.tokens);
    this.isTrial = true;
      this.setting.activation_type = 'trial';
  }
  }

  activate() {
      this.setting.keyboard.layout = ['TN', 'DZ', 'MA'].includes(
        this.setting.country
      )
        ? 'AZERTY'
        : 'QWERTY';
        this.setting.learning_language = 'ar';
        this.generateTrialKey();
        if (this._electronService.isElectronApp) {
      this._electronService.ipcRenderer.send('activateKey', this.setting);
      this._electronService.ipcRenderer.on(
        'activateKeyReady',
        (event, isValid, settings) => {
          if (isValid) {
            if(this.isStoreApp){
              this.start();
            } else {
              this.isActivated = true;
              this.setting = settings.settings;
            }
          } else {
            this.toasterService.clear();
            this.toast = this.toasterService.pop('error', 'حدث خطأاثناء التفعيل', 'رقم التفعيل غير صحيح.');
  
          }
        }
      );
    } else {
      this.setting.remaining_sessions = 20;
      this.setting.last_connection = this.formatTodayDate();
      this.start();
      this.cd.detectChanges();
    }
  }
  changeLanguage(e){
     ////Change App Langage
     if(this.setting.language == "en"){
      this.themeService.changeTheme('cosmic');
      this.translate.use('en');
      this.directionService.setDirection(this.directions.LTR);
    }else if(this.setting.language == "ar"){
      this.themeService.changeTheme('arabic');
      this.translate.use('ar');
      this.directionService.setDirection(this.directions.RTL);
    }
  }
  start() {
    this.zone.run(() => {
      this.changeLanguage(this.setting.language);
    localStorage.setItem('settings', JSON.stringify(this.setting));
    this.router.navigate(['/']);
    });
  }

  generateKey(tokens) {
    const chars = 5,
        segments = 4;
    let keyString = '';

    for (let i = 0; i < segments; i++) {
        let segment = '';

        for (let j = 0; j < chars; j++) {
          let k = Math.floor(Math.random() * ((tokens[i].split('').length - 1) - 0 + 1)) + 0;
            segment += tokens[i].split('')[k];
        }

        keyString += segment;

        if (i < (segments - 1)) {
            keyString += '-';
        }
    }
    return keyString;
}

formatTodayDate() {
  return new Date().toISOString().split('T')[0];
}
}
