import { courses } from "../courses/en";

export const arabic = {
    welcome: 'مرحباً',
    errors: 'نسبةالأخطاء',
    time: 'الوقت',
    speed: 'السرعة',
    accuracy: 'نسبة الدقة',
    wpm: 'كلمة/دقيقة',
    next: 'التالي',
    previous: 'السابق',
    close: 'إغلاق',
    buy: 'احجز نسختك الآن',
    taskCompleted : 'تمت المهمة',
        msgWow: 'نجاح باهر! لقد أكملت الدرس. تهانينا!',
        great: 'ممتاز!',
    time_spent: 'الوقت المستغرق',
    test_completed: 'هنيئا! لقد انتهيت من الأختبار.',
    test_completed_title: 'أنتهيت من الإختبار!',
    mac_help_info: `<i class="nb-info mr-1 ml-1"></i> الى مستخدمي حواسيب ماك:اتبع الخطوات المبينة في الرابط التالي لإضافة العربية 
    <br> تأكد ان اللوحة المضافة تحتوي على حرف ذ في الأعلى على اليسار.`,
    validation: {
        required: 'الحقل مطلوب',
    },
    app: {
        name: 'مدرس الطباعة',
        address:'فيلا رقم 10 7118 نهج علي الزليتني رقم 1 المنار 2 تونس 2092',
        phone:'الهاتف :',
        settings: {
           theme: 'القالب' ,
           language: {
               title: 'تغيير اللغة',
               ar: 'عربية',
               en: 'English'
                       },
           keyboard: {
               title: 'لوحة المفاتيح',
               hide: 'إخفاء',
               show: 'إظهار',
               showColors: 'إظهار الألوان',
               hideColors: 'إخفاء الألوان'
           },
           hands: {
               title: 'اليدين',
               hide: 'إخفاء',
               show: 'إظهار'
           }
        }
    },
    menu: {
        contact:'اتصل بنا',
        dashboard: 'لوحة التحكم',
        numberTestsRest:'إستعمال باقي',
        numberTests:'من جملة 20 إستعمالات',
        addLangue: 'إضافة اللغة العربية في جهازك',
        BuyYourCopy :'اقتني نسختك اليوم',
        BuyNow :'اشتري الآن',
        settings: 'الإعدادات',
        test: 'الإختبار',
        courses: 'الدروس',
        titleCard: 'بداية الدرس ',
        titleCardTest :'بداية الاختبار',
        coursControls : 'قائمة التصفح',
        resultat : 'الإحصائيات',
        taskCompleted : 'تمت المهمة',
        msgWow: 'نجاح باهر! لقد أكملت الدرس. تهانينا!',
        great: 'ممتاز!',
        ok :'موافق',
        cancel :'رجوع',
        auth: {
            auth: 'المصادقة',
            login: 'تسجيل دخول',
            register: 'تسجيل',
            reset_password: 'تغيير كلمة المرور'
        }
    },
    checkout:{
        adress_app: 'اقتناء التطبيق في تونس',
        your_copy_app:'يمكنكم الحصول على نسختكم على قرص مدمج في تونس من خلال زيارتكم لمكان شركتنا. للمزيد من التفاصيل, يمكنكم التواصل معناعن طريق:',
        email:'او البريد الالكتروني',
        address:'العنوان',
        activation:'تفعيل النسخة',
        code_activation:'ادخل رمز التفعيل لتفعيل نسختك',
        digital_acquisition:'الاقتناء الرقمي',
        activate_copy:'لتفعيل نسختكم عن بعد يمكنكم اقتناء نسختكم',
        educational_institutions:'المؤسسات التعليمية, المنظمات, االشركات و الموزعين',
        if_you_are_interested:'ان كنتم تجدون في منتجنا اهتماما وترغبون التواصل معنا فنحن في الاستماع. لا تفصلنا إلا خطوة على التعاون.'
    },
    help:{
        add_lang_pc : 'إضافة تفضيلات اللغة وتبديل إدخالها وعرضها في نظام التشغيل Windows',
        add_input_language:"إضافة لغة إدخال",
        change_keyboard_language:"إضافة لغة إدخال يتيح لك تعيين أمر تفضيلات لغة لمواقع الويب والتطبيقات، بالإضافة إلى تغيير لغة لوحة المفاتيح الخاصة بك",
        locate:"حدد",
        start_button:"زر البدء",
        then_settings:"، ثم الإعدادات",
        time_region_language:"> الوقت واللغة > المنطقة واللغة.",
        select_add_language:"حدد إضافة لغة",
        select_language_use:"اختر اللغة التي تريد استخدامها من القائمة، ثم حدد التالي.",
        review_features_language:"قم بمراجعة ميزات أي لغة تحتاج إلى تعيينها أو تثبيتها، وحدد تثبيت.",
        note_change_language:"ملاحظة: لتبديل لغة الإدخال التي تستخدمها في Windows 10، حدد اختصار اللغة في أقصى يسار شريط المهام، ثم حدد اللغة أو طريقة الإدخال التي تريد استخدامها. يمكنك أيضًا الضغط باستمرار على مفتاح شعار Windows",
        set_display_language:"تعيين لغة العرض الخاصة بك",
        display_language_default:"تغير لغة العرض التي تحددها اللغة الافتراضية التي يستخدمها نظام التشغيل الخاص بك بالكامل.",
        go_to_start:"انتقل إلى قائمة",
        setting_lang:"البدء",
        select_lang_win:"اختر لغة من قائمة لغة عرض Windows.",
        install_language_pack:"تثبيت حزمة لغة للغة موجودة",
        preferred_languages:"إذا كانت هناك لغة مدرجة ضمن اللغات المفضلة ولكنها لا تظهر في قائمة لغة عرض Windows، فسوف تحتاج أولاً إلى تثبيت حزمة اللغة الخاصة بها، عند توفرها.",
        choose_preferred_languages:"اختر لغة من اللغات المفضلة، ثم حدد خيارات.",
        download_language_pack:"حدد تنزيل من خيار تنزيل حزمة اللغة.",
        select_pack:"بعد تثبيت حزمة اللغة، حدد رجوع",
        click_start:"انقر فوق ابدأ",
        control_panel:"لوحة التحكم",
        change_keyboards_input_methods:"ضمن الساعة واللغة والمنطقة، انقر فوق تغيير لوحات المفاتيح أو أساليب الإدخال الأخرى.",
        if_dont_see_clock:"ملاحظة: إذا لم تتمكن من رؤية الساعة واللغة والمنطقة، فانقر فوق الفئة في القائمة عرض حسب الموجودةأعلى الصفحة.",
        region_and_language:"في مربع الحوار المنطقة واللغة، ضمن علامة التبويب لوحات مفاتيح ولغات، انقر فوق تغيير لوحات المفاتيح .",
        text_services_input_languages:"في مربع الحوار خدمات النصوص ولغات الإدخال، ضمن لغة الإدخال الافتراضية، انقر فوق اللغة التي تريد استخدامها كلغة افتراضية.",
        if_the_language:"إذا لم تكن اللغة التي تريد استخدامها كلغة إدخال افتراضية مدرجة، فأكمل الخطوات من 5 إلى 8.",
        installed_services:"ضمن الخدمات المثبتة، انقر فوق إضافة.",
        expand_the_language:"قم بتوسيع اللغة التي تريد استخدامها كلغة إدخال افتراضية، ثم قم بتوسيع لوحة المفاتيح.",
        ime:"حدد خانة الاختيار للوحة المفاتيح أو محرر أسلوب الإدخال (IME) الذي تريد استخدامه، ثم انقر فوق موافق. تتم إضافة اللغة إلى القائمة لغة الإدخال الافتراضية.",
        click_preview:"لمعاينة تخطيط لوحة المفاتيح، انقر فوق معاينة.",
        regional_and_language_options:"ملاحظة: في طريقة العرض الكلاسيكية، انقر نقراً مزدوجاً فوق الخيارات الإقليمية وخيارات اللغة، ثم فوق علامة التبويب لوحات مفاتيح ولغات.",
        note_region:"ملاحظة: في طريقة العرض الكلاسيكية، انقر نقراً مزدوجاً فوق الخيارات الإقليمية وخيارات اللغة، ثم فوق علامة التبويب لوحات مفاتيح ولغات.",
        dialog_box:"في مربع الحوار الخيارات الإقليمية وخيارات اللغة، ضمن علامة التبويب لوحات مفاتيح ولغات، انقر فوق تغيير لوحات المفاتيح.",
        under_default:"ضمن لغة الإدخال الافتراضية، انقر فوق اللغة التي تريد استخدامها كلغة افتراضية.",
        click_data:"انقر فوق خيارات التاريخ والوقت واللغة والمنطقة، ثم فوق الخيارات الإقليمية وخيارات اللغة.",
        classic_view:"ملاحظة: في طريقة العرض الكلاسيكية، انقر نقراً مزدوجاً فوق الخيارات الإقليمية وخيارات اللغة.",
        regional_dialog_box:"في مربع الحوار الخيارات الإقليمية وخيارات اللغة، انقر فوق علامة التبويب اللغات، ثم ضمن خدمات النصوص ولغات الإدخال، انقر فوق تفاصيل.",
        service_click_lang:"في مربع الحوار خدمات النصوص ولغات الإدخال، ضمن لغة الإدخال الافتراضية، انقر فوق اللغة التي تريد استخدامها كلغة افتراضية.",
        listed_lang:"إذا لم تكن اللغة التي تريد استخدامها كلغة إدخال افتراضية مدرجة، فعليك إكمال الخطوات من 5 إلى 7.",
        note_applied:"ملاحظة: يتم تطبيق اللغة الافتراضية التي تحددها على كافة البرامج المثبتة على الكمبيوتر والتي تستخدم هذا الإعداد، بما فيها البرامج المتوفرة من شركات أخرى.",
        input_language_dialog_box:"في مربع الحوار إضافة لغة إدخال، انقر فوق اللغة التي تريدها من القائمة لغة الإدخال، ثم انقر فوق موافق.",
        under_install:"ضمن الخدمات المثبتة، انقر فوق إضافة.",
        microsoft:"مايكروسوفت :",
        source:"المصادر :",
        office_support:"مكتب الدعم",

},
    activation : {
        successTitle: 'جاهز لتعلم الطباعة العربية؟',
        successText: 'للبدء في الدروس اضغط على زر انطلق !',
        activation: 'تفعيل',
        activation_key: 'رقم التفعيل',
        newAccount: 'تسجيل حساب جديد ',
        firstStep: 'الخطوة الأولى',
        secondtStep: 'الخطوة الثانية',
        fullName: 'الاسم الكامل',
        myFullName: 'اسمي',
        am: 'وأنا',
        myAge: 'عمري',
        myCountry: 'بلدي',
        fullNameMax:'يجب أن يتكون الاسم الكامل من',
        to:'إلى',
        start: 'إنطلق!',
        characters:'حرفًا',
        addressEmail: 'البريد الالكتروني',
        emailRequired:'البريد الالكتروني مطلوب!',
        password: 'كلمة المرور',
        passwordRequired:'كلمة المرور مطلوبة!',
        fullnameRequired: 'الاسم الكامل مطلوب!',
        confirmPassword: 'تأكيد كلمة المرور',
        rePasswordRequired:'تأكيد كلمة المرور مطلوب !',
        country:'البلد',
        countryRequired:'البلد مطلوبة',
        termsConditions: 'توافق على الشروط التالية',
        next: 'التالى',
        haveAccount: 'هل لديك حساب',
        signIn: 'تسجيل الدخول',
        favoriteMovie:'أفلامك المفضلة',
        previous:'السابق',
        close: 'إغلاق',
        submit:'إرسال',
        typeskeyboard:'نمط عرض لوحة المفاتيح',
        gender:{
            name :'جنس',
            male:'رجل',
            female:'امرأة',
            boy:'ولد',
            girl:'بنت'
        },
        age:"العمر",
        totalTrial:"من جملة 20 تجربة",
        totalTrialRest:"تجربة باقيه",
        tryApp:"أو أريد تجربة التطبيق اولا (المدة:  20 استعمال)",
        activateCopy:"لتفعيل نسختك عليك ادخال رقم التفعيل في الحقل التالي. بامكانك تفعيل نسخة تجريبية للاطلاع على التطبيق.",
        activateCopyWeb: "للبدأ في استعمال معلم الطباعة العربية انقر زر ارسل."
    },
    settings : {
        save:'حفظ',
        settings:'إعدادات',
        applicationLangage :'لغة التطبيق :',
        english:'الإنجليزية',
        arabic:'العربية',
        french:'الفرنسية',
        learningLangage :'تعلم اللغة :',
        keyboardLayout :'تخطيط لوحة المفاتيح :',
        keyboard :'لوحة المفاتيح :',
        hands :'أيادي :',
        hidden:'مخفي',
        info : 'هذه الميزة غير متوفرة في النسخة الحالية',
        fullName : 'الاسم',
        age: 'العمر',
        country:'البلد',
        gender:{
            name :'الجنس'
        }
    },
    footer : {
        typingTutor:'مدرس الطباعة العربية',
        createdWith: 'تم إنشاؤها ',
        by:'بواسطة',
        noqta:'نقطة',

    },
    beginner_level: 'مستوى مبتدأ',
    advanced_level: 'مستوى متقدم',
    description: 'الدرس يخص المبتدئين',
    lesson_advanced: 'الدرس يخص المتقدمين',
    lesson_one: 'الدرس الاول',
    lesson_tow: 'الدرس الثاني',
    lesson_three: 'الدرس الثالث',
    lesson_fourth: 'الدرس الرابع',
    task_1: 'التمرين الاول',
    task_2: 'التمرين الثاني',
    task_3: 'التمرين الثالث',
    task_4: 'التمرين الرابع',
    task_5: 'التمرين الخامس',
    task_6: 'التمرين السادس',
    home_role: 'الصف المنزل',
    top_row: 'الصف العلوي',
    bottom_row: 'الصف السفلي',
    top_key_row: 'مفاتيح الصف العلوي الذي يوجد فوق الصف المنزل مباشرة.',
    bottom_key_row: 'مفاتيح الصف السفلي الذي يوجد تحت الصف المنزل مباشرة.',
    position_finger: 'هذا الصف هو الموضع الأولي للأصابع.',
    second_tranche: 'الحصة الثانية',
    description_first_lesson: 'وصف الدرس الاول',
    description_second_lesson: 'وصف الدرس الثاني',
    description_lesson_three: 'وصف الدرس الثالث',
    description_lesson_fourth: 'وصف الدرس الرابع',
    tour: {
        content_1: "القائمة تمكنك من الاتنقال بين الصفحات الرئيسية للتطبيق",
        title_1: "القائمة الجانبية",
        content_2: "يتكون كل درس من حصص. للبدأ في التمرين انقر العنوان",
        title_2: "الدرس",
        content_3: "يتكون كل درس من حصص وتحتوي كل حصة على تمارين.",
        title_3: "الحصة",
        content_4: "كل تمرين يدربك على كلمات مكونة من مفاتيح محددة.",
        title_4: "التمرين",
        content_5: "يمكنك التواصل معنا عبر القنوات الاعتيادية",
        title_5: "التواصل معنا",
        content_6: "يمكنك اقتناء نسخة رقمية عبر وسائل الدفع الالكتروني وتفعيل نسختك هنا",
        title_6: "الإقتناء والتفعيل",
    },
};