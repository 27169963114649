import { Injectable } from '@angular/core';
import { debug } from 'util';

enum Position {
  start = 0,
  middle,
  end,
  isolated
}
@Injectable()
export class KeyboardService {
  locale = 'ar';
  keyboardLayout = {
    en: ['`1234567890-=', 'qwertyuiop[]\\', 'asdfghjkl;\'', 'zxcvbnm,./'],
    fr: ['<&}\'(§è!çà)-', 'azertyuiop^$', 'qsdfghjklmù`', '@wxcvbn,;:='],
    ar: ['ذ١٢٣٤٥٦٧٨٩٠-=', 'ضصثقفغعهخحجد', 'شسيبلاتنمكط\\', 'ذئءؤر\uFEFBىةوزظ']
  };

  arabicLetters = {
    ا: '\u0627\uFE8E\uFE8E\uFE8D',
    أ: '\u0623\uFE84\uFE84\u0623',
    إ: '\u0625\uFE88\uFE88\u0625',
    آ: '\u0622\uFE82\uFE82\uFE81',
    ب: '\uFE91\uFE92\uFE90\uFE8F',
    ت: '\uFE97\uFE98\uFE96\uFE95',
    ث: '\uFE9B\uFE9C\uFE9A\uFE99',
    ض: '\uFEBF\uFEC0\uFEBE\uFEBD',
    ص: '\uFEBB\uFEBC\uFEBA\uFEB9',
    ق: '\uFED7\uFED8\uFED6\uFED5',
    ف: '\uFED3\uFED4\uFED2\uFED1',
    غ: '\uFECF\uFED0\uFECE\uFECD',
    ع: '\uFECB\uFECC\uFECA\uFEC9',
    ه: '\uFEEB\uFEEC\uFEEA\uFEE9',
    خ: '\uFEA7\uFEA8\uFEA6\uFEA5',
    ح: '\uFEA3\uFEA4\uFEA2\uFEA1',
    ج: '\uFE9F\uFEA0\uFE9E\uFE9D',
    د: '\u062F\u062F\uFEAA\uFEA9',
    ش: '\uFEB7\uFEB8\uFEB6\uFEB5',
    س: '\uFEB3\uFEB4\uFEB2\uFEB1',
    ي: '\uFEF3\uFEF4\uFEF2\uFEF1',
    ل: '\uFEDF\uFEE0\uFEDE\uFEDD',
    ن: '\uFEE7\uFEE8\uFEE6\uFEE5',
    م: '\uFEE3\uFEE4\uFEE2\uFEE1',
    ك: '\uFEDB\uFEDC\uFEDA\uFED9',
    ط: '\uFEC3\uFEC4\uFEC2\uFEC1',
    ذ: '\u0630\uFEAC\uFEAC\uFEAB',
    ئ: '\uFE8C\uFE8C\uFE8A\uFE89',
    ء: '\uFE80\uFE80\uFE80\uFE80',
    ؤ: '\uFE85\uFE85\uFE85\uFE85',
    ر: '\uFEAD\uFEAE\uFEAE\uFEAD',
    ة: '\uFE93\uFE94\uFE94\uFE93',
    لا: '\uFEFB\uFEFC\uFEFC\uFEFB',
    ى: '\uFEEF\uFEEF\uFEF0\uFEEF',
    و: '\uFEED\uFEEE\uFEEE\uFEED',
    ز: '\uFEAF\uFEB0\uFEB0\uFEAF',
    ظ: '\uFEC7\uFEC8\uFEC6\uFEC5',
    ﻻ: '\uFEFB\uFEFC\uFEFC\uFEFB',
    لأ: '\uFEF7\uFEF8\uFEF8\uFEF7',
    ﻹ: '\uFEF9\uFEFA\uFEFA\uFEF9',
    ﻵ: '\uFEF5\uFEF6\uFEF6\uFEF5'
  };
  arabicToLatin = {
  AZERTY: [
      ['²', '&', 'é', '"', '\'', '(', '-', 'è', '_', 'ç', 'à', ')', '='],
    ['A', 'Z', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P', '¨', '£'],
    ['Q','S','D','F','G','H','J','K','L','M','%','µ'],
    ['\\','W','X','C','V','B','N','?','.','/','§']
  ],
  QWERTY: [
      ['`', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '-', '='],
    ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P', '[', ']'],
    ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', ';', '\'', '\\'],
    ['\\', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', ',', '.', '/']
  ]
  };
  tashkeel = [ '\u064C', '\u064B', '\u064D', '\u064E', '\u064F', '\u0650', '\u0651', '\u0652'];
  ponctuations = '؟.,’:،ـ‘';
  isolatedLettres = ['\u0627', '\u0623', '\u0625', '\u0622', '\u062F', '\u0629', '\u0649', '\u0630', '\u0631', '\u0632', '\u0648'];
  
  constructor() {}

  filterKey(key){

  }
  getLetter(wordLength, previousChar, char, isLastTashkeel, position, language, index) {
    let charForm = position;
    let letter = char;
    if (language === 'ar') {
      // Check for tashkeel
      if (this.tashkeel.includes(char) || this.ponctuations.includes(char)) {
        return char;
      }


      // Proceed to letters
      if (position === 1 && this.isolatedLettres.includes(previousChar)) {
        charForm = 0;
      }
      // Treat tashkeel case for last character
      if (position === 1 && wordLength - index === 2) {
        if (isLastTashkeel) {
          charForm = 3;
          if (!this.isolatedLettres.includes(previousChar)) {
            charForm = 2;
          }
        }
      }

      if (position === 2 && this.isolatedLettres.includes(previousChar)) {
          charForm = 3;
      }

      try {
        letter = this.arabicLetters[char].split('')[charForm];
        if (!letter) {
          letter = char;
        }
      } catch (error) {
        letter = char;
      }

      return letter;
    } else {
      return char;
    }
  }

  getCharFromLetter(letter) {
    for (const key in this.arabicLetters) {
      if (this.arabicLetters[key].indexOf(letter) > -1) {
        return key;
      }
    }
    return letter;
  }

  isTashkeel(key) {
    return this.tashkeel.includes(key);
  }

  isPonctuation(key) {
    return this.ponctuations.split('').includes(key);
  }

  isHamza(key) {
    return ['آ', 'أ', 'إ', 'لآ', 'لأ', 'لإ'].filter(char => {
      return char.localeCompare(key, this.locale) == 0;
    }).length > 0;
  }

  isShiftKey(key) {
    if (this.isTashkeel(key) || this.isPonctuation(key) || this.isHamza(key)) {
      return true;
    }
    return false;
  }
}
