import { Component, OnInit } from '@angular/core';

import { StateService } from '../../../@core/data/state.service';
import { NbThemeService } from '@nebular/theme';
import { SettingsService } from '../../../@core/data/settings.service';

@Component({
  selector: 'ngx-theme-settings',
  styleUrls: ['./theme-settings.component.scss'],
  template: `
    <h6 class="title-theme">{{'app.settings.theme' | translate}}</h6>
    <div class="settings-row">
      <a *ngFor="let theme of themes"
         href="#"
         [class.selected]="theme.selected"
         [attr.title]="theme.name"
         (click)="themeSelect(theme)">
        <i [attr.class]="theme.icon"></i>
      </a>
    </div>
    <h6>{{ 'app.settings.keyboard.title' | translate}}</h6>
    <div class="settings-row">
    <ngx-switcher
    [firstValue]="true"
      [secondValue]="false"
      [firstValueLabel]="'app.settings.keyboard.show'| translate"
      [secondValueLabel]="'app.settings.keyboard.hide'| translate"
      [value]="true"
      (valueChange)="toggleKeyobard($event)"
      [vertical]="true"
    ></ngx-switcher>
    </div>
    <div class="settings-row">
    <ngx-switcher
    [firstValue]="true"
      [secondValue]="false"
      [firstValueLabel]="'app.settings.keyboard.showColors'| translate"
      [secondValueLabel]="'app.settings.keyboard.hideColors'| translate"
      [value]="true"
      (valueChange)="toggleKeyobardColor($event)"
      [vertical]="true"
    ></ngx-switcher>
    </div>
    <h6>{{ 'app.settings.hands.title' | translate}}</h6>
    <div class="settings-row">
    <ngx-switcher
    [firstValue]="true"
      [secondValue]="false"
      [firstValueLabel]="'app.settings.hands.show'| translate"
      [secondValueLabel]="'app.settings.hands.hide'| translate"
      [value]="true"
      (valueChange)="toggleHands($event)"
      [vertical]="true"
    ></ngx-switcher>
    </div>
    <h6>{{ 'app.settings.language.title' | translate}}</h6>
    <div class="settings-row">
    <ngx-switcher
    [firstValue]="'ar'"
      [secondValue]="'en'"
      [firstValueLabel]="'app.settings.language.ar'| translate"
      [secondValueLabel]="'app.settings.language.en'| translate"
      [value]="'ar'"
      (valueChange)="toggleLanguage($event)"
      [vertical]="false"
    ></ngx-switcher>
    </div>
  `,
})
export class ThemeSettingsComponent implements OnInit {

  themes = [];
  sidebars = [];
  settings: any;
  constructor(protected stateService: StateService,
    private themeService: NbThemeService, private settingsService: SettingsService) {
    this.stateService.getThemes()
      .subscribe((themes: any[]) => this.themes = themes);

    this.stateService.getSidebarStates()
      .subscribe((sidebars: any[]) => this.sidebars = sidebars);
  }

  ngOnInit() {
    this.settingsService.settings.subscribe(settings => this.settings = settings)
  }

  themeSelect(theme: any): boolean {
    this.themes = this.themes.map((t: any) => {
      t.selected = false;
      return t;
    });

    theme.selected = true;
    this.themeService.changeTheme(theme.id);
    return false;
  }
  toggleKeyobardColor(showColor: boolean){
    this.settingsService.toggleKeyboardColor(showColor);
}

toggleKeyobard(showKeyboard: boolean){
    this.settingsService.toggleKeyboard(showKeyboard);
  }
  toggleHands(showHands: boolean){
    this.settingsService.toggleHands(showHands);
  }

  toggleLanguage(changeLanguage: string){
    this.settingsService.changeLanguage(changeLanguage);
  }

  sidebarSelect(sidebars: any): boolean {
    this.sidebars = this.sidebars.map((s: any) => {
      s.selected = false;
      return s;
    });

    sidebars.selected = true;
    this.stateService.setSidebarState(sidebars);
    return false;
  }
}
