import { TourNgxBootstrapModule } from 'ngx-tour-ngx-bootstrap';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule,TypeaheadModule  } from 'ngx-bootstrap';
import { KeyboardComponent } from './components/keyboard/keyboard.component';
import { HandsComponent } from './components/hands/hands.component';
import { NgxTextareaComponent } from './components/textarea/ngx-textarea.component';
import { CourseControlsComponent } from './components/course-controls/course-controls.component';
import { CourseStatsComponent } from './components/course-stats/course-stats.component';
import { StatusCardComponent } from './components/status-card/status-card.component';
import { TreeComponent } from './components/tree/tree.component';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';


import {
  NbActionsModule,
  NbCardModule,
  NbLayoutModule,
  NbMenuModule,
  NbRouteTabsetModule,
  NbSearchModule,
  NbSidebarModule,
  NbTabsetModule,
  NbThemeModule,
  NbUserModule,
  NbListModule,
  NbCheckboxModule,
  NbPopoverModule,
  NbContextMenuModule,
  NbAlertModule,
  NbInputModule,
  NbStepperComponent,
  NbStepperModule,
} from '@nebular/theme';

import { NbSecurityModule } from '@nebular/security';

import {
  FooterComponent,
  HeaderComponent,
  SearchInputComponent,
  ThemeSettingsComponent,
  SwitcherComponent,
  LayoutDirectionSwitcherComponent,
  ThemeSwitcherComponent,
  ThemeSwitcherListComponent,
} from './components';
import { CapitalizePipe, PluralPipe, RoundPipe, TimingPipe } from './pipes';
import {
  OneColumnLayoutComponent,
  SampleLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
} from './layouts';
import { DEFAULT_THEME } from './styles/theme.default';
import { COSMIC_THEME } from './styles/theme.cosmic';
import { CORPORATE_THEME } from './styles/theme.corporate';
import { ARABIC_THEME } from './styles/theme.arabic';
import { TreeNode, TreeModule } from 'angular-tree-component';
import { IndicatorsComponent } from './components/indicators/indicators.component';
import { IndicatorCardComponent } from './components/indicators-card/indicator-card.component';
import { TranslateModule } from '@ngx-translate/core';
import { ActivationComponent, NbAuthBlockComponent,
   NbAuthComponent, NbRegisterComponent,
    NbLogoutComponent, NbRequestPasswordComponent,
     NbResetPasswordComponent } from './components/auth';
import { NbLoginComponent } from './components/auth';
import { RouterModule } from '@angular/router';
import { NbStepComponent } from '@nebular/theme/components/stepper/step.component';
import { KeyboardService } from '../@core/data/keyboard.service';
import { ToasterModule } from 'angular2-toaster';
import { ToasterService } from 'angular2-toaster';
import { CarouselModule } from 'ngx-bootstrap/carousel';

const BASE_MODULES = [CommonModule, FormsModule, ReactiveFormsModule];

const NB_MODULES = [
  NbCardModule,
  NbLayoutModule,
  NbTabsetModule,
  NbRouteTabsetModule,
  NbMenuModule,
  NbUserModule,
  NbListModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbCheckboxModule,
  NbPopoverModule,
  NbContextMenuModule,
  NgbModule,
  NbSecurityModule, // *nbIsGranted directive
  TreeModule,
  SweetAlert2Module,
  TranslateModule,
  NbAlertModule,
  NbInputModule,
  RouterModule,
  NbStepperModule,
  ToasterModule,
  TourNgxBootstrapModule,
  CarouselModule,
];

const COMPONENTS = [
  SwitcherComponent,
  LayoutDirectionSwitcherComponent,
  ThemeSwitcherComponent,
  ThemeSwitcherListComponent,
  HeaderComponent,
  FooterComponent,
  SearchInputComponent,
  ThemeSettingsComponent,
  OneColumnLayoutComponent,
  SampleLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
  KeyboardComponent,
    HandsComponent,
    NgxTextareaComponent,
    CourseControlsComponent,
    CourseStatsComponent,
    StatusCardComponent,
    IndicatorsComponent,
    IndicatorCardComponent,
    TreeComponent,
    NbAuthComponent,
    NbRegisterComponent,
    NbAuthBlockComponent,
    ActivationComponent,
    NbLoginComponent,
    NbLogoutComponent,
    NbRequestPasswordComponent,
    NbResetPasswordComponent,
];

const ENTRY_COMPONENTS = [
  ThemeSwitcherListComponent,
];

const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
];

const NB_THEME_PROVIDERS = [
  ...NbThemeModule.forRoot(
    {
      name: 'arabic',
    },
    [DEFAULT_THEME, COSMIC_THEME, CORPORATE_THEME, ARABIC_THEME],
  ).providers,
  ...NbSidebarModule.forRoot().providers,
  ...NbMenuModule.forRoot().providers,
  KeyboardService,
  ToasterService,

];

@NgModule({
  imports: [...BASE_MODULES, ...NB_MODULES,ModalModule.forRoot(),TypeaheadModule.forRoot()],
  exports: [...BASE_MODULES, ...NB_MODULES, ...COMPONENTS, ...PIPES],
  declarations: [...COMPONENTS, ...PIPES, ActivationComponent],
  entryComponents: [...ENTRY_COMPONENTS],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: ThemeModule,
      providers: [...NB_THEME_PROVIDERS],
    };
  }
}
