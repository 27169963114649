import { Component, Input } from '@angular/core';

@Component({
  selector: 'ngx-status-card',
  styleUrls: ['./status-card.component.scss'],
  template: `
    <nb-card (click)="on = !on" [ngClass]="{'off': !on}">
    <div class="icon-container">
    <div class="icon {{ type }}">
      <ng-content></ng-content>
    </div>
  </div>

      <div class="details">
        <div class="title text-center mb-2">{{ text }}</div>
        <div class="status text-center"> {{ unit }}</div>
      </div>
    </nb-card>
  `,
})
export class StatusCardComponent {

  @Input() text: string;
  @Input() type: string;
  @Input() unit:string;
  @Input() on = true;
}
