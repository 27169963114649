import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { filter } from 'rxjs/operators';
import { ElectronService } from 'ngx-electron';

declare const ga: any;

@Injectable()
export class AnalyticsService {
  private enabled: boolean;
subsciption: any;
  constructor(private location: Location,
    private router: Router,
    private _electronService: ElectronService) {
    this.enabled = true;
  }

  trackPageViews() {
    const settings = JSON.parse(localStorage.getItem('settings'));
    console.log(settings);
    if (this.enabled) {
      this.subsciption = this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
      )
        .subscribe(() => {
          if (this._electronService.isElectronApp) {
            this._electronService.ipcRenderer.send('pageView', this.location.path(), this.location.path() + 
            `/${settings.activation_type}/${settings.remaining_sessions}`);
          } else {
          ga('send', {hitType: 'pageview', page: this.location.path()});
          }
        });
    }
  }

  stopTrackingPageView() {
    this.subsciption.unsubscribe();
  }

  trackEvent(eventName: string) {
    if (this.enabled) {
      ga('send', 'event', eventName);
    }
  }
}
