import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import {Observable, Subscription} from 'rxjs/Rx';
import { TranslateService } from '@ngx-translate/core';

interface CardSettings {
  text: string;
  label: string;
  unit: string;
}
@Component({
  selector: 'app-indicators',
  templateUrl: './indicators.component.html',
  styleUrls: ['./indicators.component.css']
})
export class IndicatorsComponent implements OnInit, OnChanges {
  ticks = 0;
  timer;
  @Input() stats;
  @Input() start = false;
  @Output() onResetResults = new EventEmitter<any>();
  wpm = 0;
  cpm = 0;
  accuracy: any = 100;
  accuracyCard: CardSettings = {
    text: '100',
    label: 'Accuracy',
    unit: '%'
  };
  speedCard: CardSettings = {
    text: '0',
    label: 'Speed',
    unit: 'WPM'
  };
  TimerCard: CardSettings = {
    text: '',
    label: 'Time',
    unit: ''
  };
  errorCard: CardSettings = {
    text: '0',
    label: 'errors',
    unit: ''
  };

  statusCards: CardSettings[] = [
    this.accuracyCard,
    this.speedCard,
    this.TimerCard,
    this.errorCard,
  ];
  subscription: Subscription;
  constructor(private translate: TranslateService) { }

  ngOnInit() {
    this.TimerCard.text = '00:00';
    this.translate.getTranslation(this.translate.defaultLang).subscribe(translation => {
        this.errorCard.label = translation.errors;
        this.speedCard.label = translation.speed;
        this.accuracyCard.label = translation.accuracy;
        this.TimerCard.label = translation.time;
        this.speedCard.unit = translation.wpm;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if('start' in changes){
    if(changes.start.currentValue == true) {
      this.timer = Observable.timer(0,1000);
    this.subscription = this.timer.subscribe(t => setTimeout(() => {
      this.ticks = t;
      this.wpm = this.getGrossWPM();
      this.accuracy = this.getAccuracy();
      this.speedCard.text = this.getGrossWPM().toString();
      this.errorCard.text = this.stats.errors + ' / ' + this.stats.chars;
      this.accuracyCard.text = this.getAccuracy().toString();
      this.TimerCard.text = this.formatTime(t);
    }, 0));
    } else {
      // Broadcast result if any before re/initializing
      const results = {speed: this.wpm, accuracy: this.getAccuracy(), time: this.formatTime(this.ticks)};
      this.onResetResults.emit(results);
      if(this.subscription instanceof Subscription){
        this.subscription.unsubscribe();
      }
      
      this.ticks = 0;
      this.speedCard.text = '0';
      this.errorCard.text = '0 / 0';
      this.accuracyCard.text = '100';
      this.TimerCard.text = '00:00';
    }
  }
  }

  formatTime(t) {
    let minutes, seconds;
    minutes = Math.floor(t / 60) % 60;
    t -= minutes * 60;
    seconds = t % 60;

    return [
        minutes.toString().padStart(2, '0'),
        seconds.toString().padStart(2, '0')
    ].join(':');
}

getAccuracy() : string | number {
  if(this.stats.errors == 0 || this.stats.chars == 0){
    return 100;
  }
  return (((this.stats.chars-this.stats.errors)/this.stats.chars) * 100).toFixed(2);
}

getGrossWPM(){
  // (All typed characters / 5)/ time (m)
  if(this.stats.words == 0){
    return 0;
  }
  let wpm = Math.round((this.stats.chars/5)/ (this.ticks/60));
  return wpm;
}
}
