import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NbLayoutDirectionService, NbThemeService, NbLayoutDirection } from '@nebular/theme';
 
@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  directions = NbLayoutDirection;
  localSettings: any = localStorage.getItem('settings');
  private params = {fullName: 'Guest', keyboard: {show: true, show_colors: true}, hands: {show: true}, language: 'ar'};
  private settingsSource = new BehaviorSubject(this.params);
  settings = this.settingsSource.asObservable();
  constructor(private themeService: NbThemeService,
    private translate: TranslateService,
    private directionService: NbLayoutDirectionService) {
      if(this.localSettings) {
        this.localSettings = JSON.parse(this.localSettings);
      this.params.fullName = this.localSettings.fullName;
     }
    }

  toggleKeyboard(state) {
    this.params.keyboard.show = state;
    this.settingsSource.next(this.params);
  }

  toggleKeyboardColor(state) {
    this.params.keyboard.show_colors = state;
    this.settingsSource.next(this.params);
  }
  
  toggleHands(state) {
    this.params.hands.show = state;
    this.settingsSource.next(this.params);
  }

  updateFullname(state) {
    this.params.fullName = state;
  }

  changeLanguage(state) {
    this.params.language = state;
    if(state == "en"){
      this.themeService.changeTheme('cosmic');
      this.translate.use('en');
      this.directionService.setDirection(this.directions.LTR);
      // this.translateMenu();
    } else if(state == "ar"){
      this.themeService.changeTheme('arabic');
      this.translate.use('ar');
      this.directionService.setDirection(this.directions.RTL);
      // this.translateMenu();
    }
    this.settingsSource.next(this.params);
  }

  changeName(state){
    this.params.fullName = state;
    this.settingsSource.next(this.params);
  }
}
