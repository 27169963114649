import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Course } from '../../../shared/models/course';

@Component({
  selector: 'app-course-controls',
  templateUrl: './course-controls.component.html',
  styleUrls: ['./course-controls.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CourseControlsComponent implements OnInit {

  @Input() course: Course;
  @Input() task = 0;
  @Input() lesson = 0;
  @Output() taskUpdated: EventEmitter<any> = new EventEmitter();
  @Output() taskRestarted: EventEmitter<boolean> = new EventEmitter();
  @Output() lessonUpdate: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit() {
    this.course = this.course ? this.course : new Course();
  }

  nextTask(){
    if(this.task < this.getTotalTasks() - 1) {
      this.task++;
      this.taskUpdated.emit(this.task);
    } else {
      if(this.lesson < this.getTotalLessons() - 1) {
        this.lesson++;
        this.lessonUpdate.emit(this.lesson);
        this.task = 0;
        this.taskUpdated.emit(this.task);
      }
    }
  }

  private getTotalTasks() {
    if (this.course.lessons.length == 0) {
      return 0;
    }
    return this.course.lessons[this.lesson].tasks.length;
  }

  previousTask(){
    if(this.task > 0){
      this.task--;
      this.taskUpdated.emit(this.task);
    } else {
      if(this.lesson > 0){
        this.lesson--;
        this.lessonUpdate.emit(this.lesson);
        this.task = this.getTotalTasks() - 1;
        this.taskUpdated.emit(this.task);
      }
    }
  }

  resetTask(){
    this.taskRestarted.emit(true);
  }

  canNext(){
    return this.task == this.getTotalTasks() - 1  && this.lesson == this.getTotalLessons() - 1;
  }

  canPrevious(){
    return this.task == 0 && this.lesson == 0;
  }


  private getTotalLessons() {
    return this.course.lessons.length;
  }
}
