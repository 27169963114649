export const courses = [
    {
        title: 'beginner_level',
        slug: 'beginner',
        description: 'description',
        level: 'beginner',
        icon: 'nb-home',
        lessons: [
            {
                title: 'home_role',
                description: 'position_finger',
                tasks: [
                    {
                        title: 'task_1',
                        keys: [
                            'ب',
                            'ت'
                        ]
                    },
                    {
                        title: 'task_2',
                        keys: [
                            'ي',
                            'ب',
                            'ت',
                            'ن'
                        ]
                    },
                    {
                        title: 'task_3',
                        keys: [
                            'س',
                            'ي',
                            'ب',
                            'ت',
                            'ن',
                            'م'
                        ]
                    },
                    {
                        title: 'task_4',
                        keys: [
                            'ش',
                            'س',
                            'ي',
                            'ب',
                            'ت',
                            'ن',
                            'م',
                            'ك'
                        ]
                    },
                    {
                        title: 'task_5',
                        keys: [
                            'ش',
                            'س',
                            'ي',
                            'ب',
                            'ت',
                            'ن',
                            'م',
                            'ك',
                            'ط'
                        ]
                    },
                    {
                        title: 'task_6',
                        keys: [
                            'ش',
                            'س',
                            'ي',
                            'ب',
                            'ل',
                            'ا',
                            'ت',
                            'ن',
                            'م',
                            'ك',
                            'ط'
                        ]
                    }
                ]
            },
            {
                title: 'top_row',
                description: 'top_key_row',
                tasks: [
                    {
                        title: 'task_1',
                        keys: [
                            'ف',
                            'ه'
                        ]
                    },
                    {
                        title: 'task_2',
                        keys: [
                            'ق',
                            'ف',
                            'ه',
                            'خ'
                        ]
                    },
                    {
                        title: 'task_3',
                        keys: [
                            'ث',
                            'ق',
                            'ف',
                            'ه',
                            'خ',
                            'ح'
                        ]
                    },
                    {
                        title: 'task_4',
                        keys: [
                            'ص',
                            'ث',
                            'ق',
                            'ف',
                            'ه',
                            'خ',
                            'ح',
                            'ج'
                        ]
                    },
                    {
                        title: 'task_5',
                        keys: [
                            'ض',
                            'ص',
                            'ث',
                            'ق',
                            'ف',
                            'ه',
                            'خ',
                            'ح',
                            'ج',
                            'د'
                        ]
                    },
                    {
                        title: 'task_6',
                        keys: [
                            'ض',
                            'ص',
                            'ث',
                            'ق',
                            'ف',
                            'غ',
                            'ع',
                            'ه',
                            'خ',
                            'ح',
                            'ج',
                            'د'
                        ]
                    }
                ]
            },
            {
                title: 'bottom_row',
                description: 'bottom_key_row',
                tasks: [
                    {
                        title: 'task_1',
                        keys: [
                            'ر',
                            'ى'
                        ]
                    },
                    {
                        title: 'task_2',
                        keys: [
                            'ؤ',
                            'ر',
                            'ى',
                            'ة'
                        ]
                    },
                    {
                        title: 'task_3',
                        keys: [
                            'ء',
                            'ؤ',
                            'ر',
                            'ى',
                            'ة',
                            'و'
                        ]
                    },
                    {
                        title: 'task_4',
                        keys: [
                            'ئ',
                            'ء',
                            'ؤ',
                            'ر',
                            'ى',
                            'ة',
                            'و',
                            'ز'
                        ]
                    },
                    {
                        title: 'task_5',
                        keys: [
                            'ذ',
                            'ئ',
                            'ء',
                            'ؤ',
                            'ر',
                            'ى',
                            'ة',
                            'و',
                            'ز',
                            'ظ'
                        ]
                    },
                    {
                        title: 'task_6',
                        keys: [
                            'ذ',
                            'ئ',
                            'ء',
                            'ؤ',
                            'ر',
                            'لا',
                            'ى',
                            'ة',
                            'و',
                            'ز',
                            'ظ'
                        ]
                    }
                ]
            }
            
        ]
    },
    {
        title: 'advanced_level',
        slug: 'advanced',
        description: 'second_tranche',
        icon: 'nb-home',
        level: 'intermediate',
        lessons: [
            {
                title: 'lesson_one',
                description: 'description_first_lesson',
                tasks: [
                    {
                        title: 'task_1',
                        keys: [
                            'ب',
                            'ت'
                        ]
                    },
                    {
                        title: 'task_2',
                        keys: [
                            'ف',
                            'ب',
                            'ت',
                            'ه'
                        ]
                    },
                    {
                        title: 'task_3',
                        keys: [
                            'ؤ',
                            'ف',
                            'ب',
                            'ت',
                            'ه',
                            'ة'
                        ]
                    },
                    {
                        title: 'task_4',
                        keys: [
                            'ؤ',
                            'ف',
                            'ب',
                            'ا',
                            'ل',
                            'ت',
                            'ه',
                            'ة'
                        ]
                    },
                    {
                        title: 'task_5',
                        keys: [
                            'ؤ',
                            'ف',
                            'ي',
                            'ب',
                            'ا',
                            'ل',
                            'ن',
                            'ت',
                            'ه',
                            'ة'
                        ]
                    },
                    {
                        title: 'task_6',
                        keys: [
                            'ؤ',
                            'ف',
                            'س',
                            'ي',
                            'ب',
                            'ا',
                            'ل',
                            'ن',
                            'م',
                            'ت',
                            'ه',
                            'ة'
                        ]
                    }
                ]
            },
            {
                title: 'lesson_tow',
                description: 'description_second_lesson',
                tasks: [
                    {
                        title: 'task_1',
                        keys: [
                            'ف',
                            'ه'
                        ]
                    },
                    {
                        title: 'task_2',
                        keys: [
                            'ف',
                            'ه',
                            'ب',
                            'ت'
                        ]
                    },
                    {
                        title: 'task_3',
                        keys: [
                            'ف',
                            'ه',
                            'ي',
                            'ب',
                            'ت',
                            'ن'
                        ]
                    },
                    {
                        title: 'task_4',
                        keys: [
                            'ق',
                            'ف',
                            'ه',
                            'خ',
                            'ي',
                            'ب',
                            'ت',
                            'ن'
                        ]
                    },
                    {
                        title: 'task_5',
                        keys: [
                            'ؤ',
                            'ق',
                            'ف',
                            'ه',
                            'خ',
                            'ي',
                            'ب',
                            'ت',
                            'ن',
                            'ة'
                        ]
                    },
                    {
                        title: 'task_6',
                        keys: [
                            'ء',
                            'ؤ',
                            'ق',
                            'ف',
                            'ه',
                            'خ',
                            'ي',
                            'ب',
                            'ت',
                            'ن',
                            'و'
                        ]
                    }
                ]
            },
            {
                title: 'lesson_three',
                description: 'description_lesson_three',
                tasks: [
                    {
                        title: 'task_1',
                        keys: [
                            'ب',
                            'ي',
                            'ت',
                            'ن',
                        ]
                    },
                    {
                        title: 'task_2',
                        keys: [
                            'ش',
                            'س',
                            'ب',
                            'ي',
                            'ت',
                            'ن',
                            'م',
                            'ك'
                        ]
                    },
                    {
                        title: 'task_3',
                        keys: [
                            'ء',
                            'ؤ',
                            'ش',
                            'س',
                            'ب',
                            'ي',
                            'ت',
                            'ن',
                            'م',
                            'ك',
                            'ة',
                            'و'
                        ]
                    },
                    {
                        title: 'task_4',
                        keys: [
                            'ئ',
                            'ء',
                            'ؤ',
                            'ش',
                            'س',
                            'ب',
                            'ي',
                            'ت',
                            'ن',
                            'م',
                            'ك',
                            'ة',
                            'و',
                            'ز',
                            'ظ'
                        ]
                    },
                    {
                        title: 'task_5',
                        keys: [
                            'ذ',
                            'ئ',
                            'ف',
                            'ر',
                            'ب',
                            'ت',
                            'ى',
                            'ه',
                            'ز',
                            'ظ'
                        ]
                    },
                    {
                        title: 'task_6',
                        keys: [
                            'ث',
                            'ق',
                            'ح',
                            'خ',
                            'س',
                            'ي',
                            'ن',
                            'م',
                            'ؤ',
                            'ء',
                            'و',
                            'ز'
                        ]
                    }
                ]
            },
            {
                title: 'lesson_fourth',
                description: 'description_lesson_fourth',
                tasks: [
                    {
                        title: 'task_1',
                        keys: [
                            '4',
                            '7'
                        ]
                    },
                    {
                        title: 'task_2',
                        keys: [
                            '3',
                            'ب',
                            'ل',
                            '8'
                        ]
                    },
                    {
                        title: 'task_3',
                        keys: [
                            'م',
                            'ص',
                            '4',
                            'ب',
                            'ك',
                            '9'
                        ]
                    },
                    {
                        title: 'task_4',
                        keys: [
                            '1',
                            'ح',
                            'ئ',
                            'لا',
                            'ث',
                            'ل',
                            'ي',
                            'ف'
                        ]
                    },
                    {
                        title: 'task_5',
                        keys: [
                            'ط',
                            'خ',
                            'و',
                            'ة',
                            'ى',
                            '5',
                            '0',
                            'ا',
                            'لا',
                            'غ'
                        ]
                    },
                    {
                        title: 'task_6',
                        keys: [
                            'س',
                            'ث',
                            'ح',
                            'ك',
                            'ا',
                            '3',
                            'لا',
                            'ز',
                            'ض',
                            'ق',
                            'ل',
                            'ئ',
                            'ق',
                            'ح',
                            'ى',
                        ]
                    }
                ]
            }
        ]
    }
];